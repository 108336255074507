import { Atom } from ":mods";
import { createSignal, splitProps } from "solid-js";
import { MoreInformationViewProps } from "./model";

export function MoreInformation(props:MoreInformationViewProps) {
  const [local, others] = splitProps(props, ["data"]);
  const radioContainerStyle = "flex gap-10px border border-2 border#p border-solid p-20px";
  const dropdownContainerStyle = "flex justify-between border border-2 border#p border-solid p-15px";
  const textInputStyle = "p-15px border border-2px border#p border-solid";
  const [moduleOpen, setModuleOpen] = createSignal(false);
  const handleAccordionClicks = () => {
    setModuleOpen(moduleOpen()?false:true);
  };

  return (
    <Atom.Foldable.Accordion
      arrowMode="end"
      iconClass={`w-24px h-24px  icon-local:down-arrow ${moduleOpen() && "!rotate-180"}`}
      onclick={handleAccordionClicks}
      title={
        <section class="flex flex-row justify-start items-center w-full font-700">
          <span>More Information (Foundation My Exhibition Submission)</span>
        </section>
      }
      class="border-2 border-solid border#p px-2 py-20px mb-20px"
    >
      <section class="flex flex-col gap-20px mt-40px">
        <div class="flex flex-col gap-10px">
          <div class="flex flex-col gap-20px">
            <p class="text-16px font-700">Terms and conditions</p>
            <p class="text-14px font-400">{local.data?.terms_and_conditions ? "Accepted" : "Not Accepted"}</p>
          </div>
        </div>
        <p class="text-16px font-700">Education Info</p>
        <div class="flex flex-col gap-10px">
          <p class="text-16px leading-22.4px">What is your highest academic qualification</p>
          {/* TODO: read the values from the backend once completed */}
          {local.data.qualification_options?.map((qualification) => {
            return (
              <div class={radioContainerStyle}>
                <input type="radio" readOnly checked={local.data.qualification == qualification?.[1]} class={"w-25px h-25px cursor-pointer"} />
                <p>{qualification?.[1] || ""}</p>
              </div>
            );
          })}
        </div>

        <div class="flex flex-col gap-10px">
          <p>What field did you studying?</p>
          <div class={dropdownContainerStyle}>
            <p>{local.data?.study_field}</p>
            <i class="icon-local:down-arrow w-25px h-25px" />
          </div>
        </div>
        <div class="flex flex-col gap-20px">
          <p class="text-14px font-700 leading-21px">Region to participate</p>
          <p>
            If you were accepted onto the programme, which regions would you be able to participate on the in-person
            training in? You will be responsible for your own travel and accommodation expenses
          </p>
           {/* TODO: read the values from the backend once completed */}
          {local.data.region_lest?.map((region) => {
            return (
              <div class={radioContainerStyle}>
                <input type="checkbox" readOnly checked={!!local.data.region.find((r)=> r.name == region?.[1])?.name } class={"w-25px h-25px cursor-pointer"} />
                <p>{region?.[1] || ""}</p>
              </div>
            );
          })}
        </div>
        <div class="flex flex-col gap-10px">
          <p class="text-16px font-700 mb-20px">Experience Info</p>
          <div class="flex flex-col gap-10px">
            <p class="text-16px leading-22.4px">Please tell us what is your job title</p>
            <div class={textInputStyle}>{local.data?.experience}</div>
          </div>
          <div class="flex flex-col gap-10px">
            <p class="text-16px leading-22.4px">Do you currently work or volunteer in a Museum?</p>
            <div class={radioContainerStyle}>
              <input
                type="radio"
                readOnly
                checked={local.data?.volunteer_in_a_museum}
                class={"w-25px h-25px cursor-pointer"}
              />
              <p>Yes</p>
            </div>
            <div class={radioContainerStyle}>
              <input
                type="radio"
                readOnly
                checked={!local.data?.volunteer_in_a_museum}
                class={"w-25px h-25px cursor-pointer"}
              />
              <p>No</p>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-10px">
          <p class="text-16px leading-22.4px">Which museum do you work or volunteer in?</p>
          <div class={textInputStyle}>{local.data?.museum_name}</div>
        </div>
        <div class="flex flex-col gap-10px">
          <p class="text-16px leading-22.4px">Which Department do you work or Volunteer in?</p>
          <div class={textInputStyle}>{local.data?.department}</div>
        </div>

        <div class="flex flex-col gap-10px">
          <p class="text-16px leading-22.4px">How many years have you worked in a museum or within the museum sector</p>
          {local.data.work_period_list.map((w_period)=>{

            return (
              <div class={radioContainerStyle}>
            <input type="radio" readOnly checked={local.data.work_period == w_period?.[1]} class={"w-25px h-25px cursor-pointer"} />
            <p>{w_period?.[1] || ""}</p>
          </div>
            )
          })}
        </div>
        <div class="flex flex-col gap-20px">
          <p class="text-16px font-700">Your CV</p>
          <a href={local.data?.cv} target={"_blank"} class={`${textInputStyle} w-max `}>
            {local.data?.cv.split("cvs/")[1].split("?")[0]} (PDF)
          </a>
        </div>
      </section>
    </Atom.Foldable.Accordion>
  );
}
